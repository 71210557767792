import React from 'react';
// import LayoutMain from 'layouts/layout-main';
// import { Helmet } from 'react-helmet';

const NotFoundPage = () => (
  // <LayoutMain>
  // <Helmet title="Swiftlane – 404 Not Found" />
  <div>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadaaaaaness.</p>
  </div>
  // </LayoutMain>
);

export default NotFoundPage;
